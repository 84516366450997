<template>
	<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange"
	 :configurable="false" :queryParam="queryParam">
		<el-table slot="elList" ref="warehouseListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
		 @current-change="handleCurrentChange" highlight-current-row>
			<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
			<el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams" :key="index" :prop="col.FieldName"
			 :label="col.DisplayName" :render-header="bindFilter(queryParam,col,ignoreField)" :fixed="index==0&&(!config||!config.isDetailDisplay)"
			 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0" v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<6))">
				<template slot-scope="scope">
					<span> {{ scope.row[col.FieldName] }}</span>
				</template>
			</el-table-column>
		</el-table>
	</fixed-list>
</template>
<script>
	export default {
		mounted() {
			this.initialize(); //加载表格数据
		},
		data() {
			return {
				ignoreField: [],
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					params: {}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0,
				},
				MemberId: 0,
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				MemberCode: '',
				code: "",
				index: 0
			},
			selectCondition: {}
		},
		methods: {
			onDataSourceChange(ds) {
				var _this = this;
				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			handleCurrentChange: function(val) {
				val.code = this.config.code;
				val.index = this.config.index;
				this.$emit("onSelectedRow", val);
				this.$emit("onOk", true);
			},
			handleSelectionChange(val) {
				val.code = this.config.code;
				val.index = this.config.index;
				this.multipleSelection = val;
				this.$emit("onSelectedRow", val);
			},
			initialize: function() {
				var _this = this;
				_this.$ajax.send("omsapi/memberinfo/searchmemberinfobycode", "get", {
					code: _this.config.MemberCode
				}, (data) => {
					if (data.Result != null) {
						_this.MemberId=data.Result.MemberId;
						_this.onPageChange(_this.queryParam);
					} else {
						this.addressList = null;
					}
				});
				// _this.queryParam.params.MemberId = _this.config.MemberId;
				// if (_this.selectCondition) {
				//     _this.Utils.extend(_this.queryParam.params, _this.selectCondition);
				// }
				// this.$ajax.query("omsapi/memberinfo/searchmemberproductlist", "post", _this.queryParam, (result) => {
				//     _this.dataSource = result;
				// });
			},
			onPageChange(param) {
				var _this = this;
				_this.queryParam.params.MemberId = _this.MemberId;
				if (_this.selectCondition) {
					_this.Utils.extend(param.params, _this.selectCondition);
				}
				this.$ajax.query("omsapi/memberinfo/searchmemberproductlist", "post", _this.queryParam, (result) => {
					_this.dataSource = result;
				});
			},
		}
	};
</script>
